var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal qna" }, [
    _c("div", { staticClass: "modal_body" }, [
      _c("div", { staticClass: "flexB title" }, [
        _c("h3", [_vm._v("문의 답변")]),
        _c("button", { staticClass: "btn", on: { click: _vm.closeModal2 } }, [
          _c("span")
        ])
      ]),
      _c("div", { staticClass: "content" }, [
        _c("article", [
          _c("div", { staticClass: "flexB" }, [
            _c("p", [_vm._v(_vm._s(_vm.qnaData.title))]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.moment(_vm.qnaData.createdAt).format("YYYY.MM.DD HH:mm")
                )
              )
            ])
          ]),
          _c("pre", [_vm._v(_vm._s(_vm.qnaData.content))])
        ]),
        _c("article", { staticClass: "reply" }, [
          _c("div", { staticClass: "flexB" }, [
            _c("p", [_vm._v("답변 내용")]),
            _vm.qnaData.status
              ? _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm
                        .moment(_vm.qnaData.updatedAt)
                        .format("YYYY.MM.DD HH:mm")
                    )
                  )
                ])
              : _vm._e()
          ]),
          _c("pre", [
            _vm._v(
              _vm._s(
                _vm.qnaData.replys && _vm.qnaData.replys[0]
                  ? _vm.qnaData.replys[0].contents
                  : "답변 대기중..."
              )
            )
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "modal_bg", on: { click: _vm.closeModal2 } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }